import { translate } from 'react-switch-lang';
import PropTypes from "prop-types";

import Spinner from "./Spinner";
import AvtomatReady from "./AvtomatReady";
import AvtomatNotReady from "./AvtomatNotReady";

function AvtomatReadyContainer({ t, loading, avtomatId, error }) {
    let content = null;

    switch (error) {
        case false:
            content = <AvtomatReady avtomatId={avtomatId} />;
            break;
        case 'notFound':
            content = <AvtomatNotReady avtomatId={avtomatId} message={t('avtomatNotReady.notFound')} />;
            break;
        case 'notWorking':
            content = <AvtomatNotReady avtomatId={avtomatId} message={(t('avtomatNotReady.notWorking'))} nearestButton />;
            break;
        case 'cashOnly':
            content = <AvtomatNotReady avtomatId={avtomatId} message={(t('avtomatNotReady.cashOnly'))} nearestButton />;
            break;
        case 'avtomatIsBusy':
            content = <AvtomatNotReady avtomatId={avtomatId} message={(t('avtomatNotReady.avtomatIsBusy'))} />;
            break;
        default:
    }

    return (
        <div className='ready-container'>
            <div className='ready-content'>
                {loading ? <Spinner/> : content}
            </div>
        </div>
    )
}

AvtomatReadyContainer.propTypes = {
    t: PropTypes.func.isRequired
}

export default translate(AvtomatReadyContainer);