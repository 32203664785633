import { createContext, useContext, useState } from "react";

const ShowMenuContext = createContext();

export default function ShowMenuProvider({ children }) {
    const [showMenu, setShowMenu] = useState(false);

    const handleShowMenu = () => setShowMenu(true);
    const handleCloseMenu = () => setShowMenu(false);

    return (
        <ShowMenuContext.Provider value={{ showMenu, handleShowMenu, handleCloseMenu }}>
            { children }
        </ShowMenuContext.Provider>
    );
}

export function useShowMenu() {
    return useContext(ShowMenuContext);
}