import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Body from "../components/Body";
import AvtomatAddress from "../components/AvtomatAddress";
import AvtomatReadyContainer from "../components/AvtomatReadyContainer";

export default function ReadyPage() {
    const { avtomatId } = useParams();
    const [loading, setLoading] = useState(true);
    const [address, setAddress] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        (async () => {
            const response = await fetch(`/api/status/${avtomatId}`);
            if (response.ok) {
                const status = await response.json();
                setAddress(status.address);
                setError(status.error);
                setLoading(false);
            }
        })();
    }, [avtomatId]);

    return (
        <Body>
            <AvtomatAddress address={address}/>
            <AvtomatReadyContainer loading={loading} avtomatId={avtomatId} error={error} />
        </Body>
    )
}
