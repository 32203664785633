import React from 'react';
import Loader from "react-loader-spinner";
import {translate} from 'react-switch-lang';
import PropTypes from "prop-types";

class Nearest extends React.Component {
	
	state = {
		loading: true,
		avtomatId: this.props.match.params.id,
		avtomats: [],
	}

	componentDidMount() {
		fetch(`/api/nearest_avtomats/${this.state.avtomatId}`)
			.then((resp) => {
				return resp.json()
			})
			.then((avtomats) => {
				this.setState({
					loading: false,
					avtomats: avtomats.avtomats
				})
			})
	};

	loader = () => {
		return (
			<div className="ready-content">
				<Loader
					type="Puff"
					color="#1c93da"
					height={100}
					width={100}
					timeout={2000} //2 sec
				/>
			</div>
		)
	}

	renderAvtomatsList = () => {
		const { t } = this.props;
		const elements = this.state.avtomats.map((avtomat) => {
			return <li key={avtomat.avtomat_number}>{avtomat.address}</li>
		});

		return (
			<React.Fragment>
				<h1 className='thank-title'>{t('closed.vodomatLocation')}</h1>
				<ul>
					{ elements }
				</ul>
				<button className='thank-button' onClick={ ()=> this.props.history.push('/')}>Ok</button>
			</React.Fragment>
		)
	}

	renderNotFound = () => {
		const { t } = this.props;
		return (
			<React.Fragment>
				<h1 className='thank-title'>{t('closed.vodomatLocation')}</h1>
				<h2>{t('nearest.notFound')}</h2>
				<button className='thank-button' onClick={ ()=> this.props.history.push('/')}>Ok</button>
			</React.Fragment>
		)
	}

	render() {
		const { loading } = this.state;

		const content = this.state.avtomats.length > 1 ? this.renderAvtomatsList() : this.renderNotFound()

		return (
			<div className="thank-background">
				<div className='thank-container'>
					<div className="thank-content nearest-content">
						{ loading ? this.loader(): content}
					</div>
				</div>
			</div>
		);
	}
}

Nearest.propTypes = {
	t: PropTypes.func.isRequired,
};

export default translate(Nearest);
