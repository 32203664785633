import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";

import { useShowMenu } from '../contexts/ShowMenuProvider';

export default function HomeButton() {
    const { handleCloseMenu } = useShowMenu();

    return (
        <div className="home-btn">
            <Link to={'/'} onClick={handleCloseMenu}>
                <FontAwesomeIcon icon={faHome} size='lg' />
            </Link>
        </div>
    );
}