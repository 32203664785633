import React, { useState } from 'react';
import { translate } from 'react-switch-lang';
import PropTypes from "prop-types";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

function AvtomatReady({ t, avtomatId }) {
    const [showDrainWaterMsg, setShowDrainWaterMsg] = useState(false);

    const drainWaterQ = (
        <React.Fragment>
            <h1 className='ready-title__content'>
                {t('avtomatReady.question')}
                <br/>
                <span>00:00</span>
                <br/>
                <p className='ReadyMessage'>({t('avtomatReady.message')}!)</p>
            </h1>

            <div className='d-grid gap-3'>
                <Button
                    className='ReadyButton ReadyButtonYes'
                    as={Link} to={`/water/${avtomatId}`}
                >
                    {t('avtomatReady.buttonYes')}
                </Button>
                <Button
                    className='ReadyButton ReadyButtonNo'
                    onClick={() => setShowDrainWaterMsg(true)}
                >
                    {t('avtomatReady.buttonNo')}
                </Button>
            </div>
        </React.Fragment>
    )

    const drainWaterMsg = (
        <React.Fragment>
            <h1 className='ready-title__content'>
                {t('avtomatReady.drainWaterMsg')}
                <br />
                <span style={{color: 'red'}}>00:00</span>
            </h1>

            <Link className='ready-button' to={`/water/${avtomatId}`}>
                {t('avtomatReady.buttonContinue')}
            </Link>
        </React.Fragment>
    )

    return (
        <div className='ready-content'>
            { showDrainWaterMsg ? drainWaterMsg : drainWaterQ }
        </div>
    )
}

AvtomatReady.propType = {
    t: PropTypes.func.isRequired
}

export default translate(AvtomatReady);