import { translate } from 'react-switch-lang';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";

function AvtomatNotReady({ t, message, avtomatId, nearestButton }) {
    return (
        <div className="closed-content">
            <div className='closed-content--message'>
                <p className='closed-content--text'>
                    {message}
                </p>
            </div>
            {
                nearestButton
                    ?
                    <Link to={'/nearest/' + avtomatId} className='ready-button'>
                        <span>{t('avtomatNotReady.nearestButton')}</span>
                    </Link>
                    :
                    <Link to={'/'} className='ready-button'>
                        <FontAwesomeIcon icon={faHome} size='lg' />
                    </Link>
            }
        </div>
    )
}

AvtomatNotReady.propTypes = {
    t: PropTypes.func.isRequired
}

export default translate(AvtomatNotReady);