import React from 'react';
import queryString from "query-string";
import PropTypes from "prop-types";
import {translate} from "react-switch-lang";

const AfterPayment = (props) => {
    const { t } = props;
    const { id } = props.match.params
    const params = queryString.parse(props.location.search);

    const onButtonIssue = () => {
        props.history.push(`/issue/${id}`);
    }

    const Message = () => {
        const homeUrl = params.status === 'success' ? '/' : `/?vodomat_id=${id}`;
        return (
            <React.Fragment>
                <h1 className='thank-title'>{t(`${params.status}AfterPayment.title`)}</h1>
                <h1 className='thank-title'>{t(`${params.status}AfterPayment.desc`)}</h1>
                <button className='thank-button' onClick={ ()=> props.history.push(homeUrl)}>
                    {t(`${params.status}AfterPayment.button`)}
                </button>
                { params.status === 'success'
                ?
                    <div className='issue-btn' onClick={ onButtonIssue }>
                        {t(`${params.status}AfterPayment.issue`)}
                    </div>
                :
                    null
                }
            </React.Fragment>
        )
    }

    return (
        <div className="thank-background">
            <div className='thank-container'>
                <div className="thank-content">
                    <Message />
                </div>
            </div>
        </div>
    )
}

AfterPayment.propTypes = {
	t: PropTypes.func.isRequired,
};

export default translate(AfterPayment);
