import Container from 'react-bootstrap/Container';

export default function Document({ url, title }) {
    return (
        <Container className='Document'>
            <iframe
                src={url}
                title={title}
                style={{ width: '100%', height: '100vh' }}
            />
        </Container>
    )
}