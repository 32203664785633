import React from 'react';
import PropTypes from "prop-types";
import {translate} from "react-switch-lang";

class Issue extends React.Component {
    
    state = {
        avtomatId: this.props.match.params.id,
        address: '',
        text: '',
        email: '',
        phoneNumber: '',
        success: null,
        showPrimaryBox: true,
        showContactForm: false
    }

    componentDidMount() {
        fetch(`/api/status/${this.state.avtomatId}`)
            .then((resp) => {
                return resp.json()
            })
            .then((avtomat) => {
                this.setState({
                    address: avtomat.address
                })
            })
    }

    changeShowPrimaryBox = () => {
        this.setState({
            text: '',
            showPrimaryBox: !this.state.showPrimaryBox
        });
    }

    handleChangeErrorField = (e) => {
        this.setState({
            text: e.target.value
        })
    }

    handleChangeContactField = (event, field) => {
        this.setState({
            [field] : event.target.value
        })
    }

    onSubmitReportForm = (e) => {
        e.preventDefault()
        if (!this.state.text) {
            return
        }
        this.setState({
            showContactForm: true
        })
    }

    onSubmitContactForm = (e) => {
        e.preventDefault();
        this.sendData();
    }

    sendData = () => {
        fetch('/api/issue', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                avtomatId: this.state.avtomatId,
                address: this.state.address,
                text: this.state.text,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber
            })
        })
        .then((res) => {
            if (res.status === 200) {
                this.setState({
                    success: true
                })
            }
        })
    }

    renderThanks() {
        const { t } = this.props;
        return (
            <div className="thank-content">
                <h1 className='thank-title'>{t('thank.report')}</h1>
                <button className='thank-button' onClick={() => {
                    this.props.history.push('/')
                }}>{t('thank.button')}</button>
            </div>
        )
    }

    renderReportForm() {
        const { t } = this.props;
        return (
            <form className='report-form' onSubmit={this.onSubmitReportForm}>
                <div className={`first-box ${this.state.showPrimaryBox ? '' : 'hidden'}`}>
                    <div>
                        <label className='report-input'>
                            <input name="text" type="radio" onChange={this.handleChangeErrorField}
                                   value={t('report.notWorking')}/>
                            <span className='report-message'>{t('report.notWorking')}</span>
                        </label>
                    </div>
                    <div>
                        <label className='report-input'>
                            <input name="text" type="radio" onChange={this.handleChangeErrorField}
                                   value={t('report.notWater')}/>
                            <span className='report-message'>{t('report.notWater')}</span>
                        </label>
                    </div>
                    <div>
                        <label className='report-input'>
                            <input name="text" type="radio" onChange={this.handleChangeErrorField}
                                   value={t('report.notAcceptCash')}/>
                            <span className='report-message'>{t('report.notAcceptCash')}</span>
                        </label>
                    </div>
                    <div>
                        <label className='report-input'>
                            <input name="text" type="radio" onChange={this.handleChangeErrorField}
                                   value={t('report.notAcceptCoin')}/>
                            <span className='report-message'>{t('report.notAcceptCoin')}</span>
                        </label>
                    </div>
                    <div>
                        <label className='report-input'>
                            <input name="text" type="radio" onChange={this.handleChangeErrorField}
                                   value={t('report.transactionFail')}/>
                            <span className='report-message'>{t('report.transactionFail')}</span>
                        </label>
                    </div>
                    <div>
                        <label className='report-input'>
                            <input name="text" type="radio" onClick={this.changeShowPrimaryBox}/>
                            <span className='report-message'>{t('report.other')}</span>
                        </label>
                    </div>
                </div>

                <div className={`second-box ${this.state.showPrimaryBox ? 'hidden' : ''}`}>
                    <label className='report-input'>
                        <textarea onChange={this.handleChangeErrorField}
                                  value={this.state.text}
                                  placeholder={t('report.textareaPlaceholder')}
                        >
                        </textarea>
                    </label>
                    <p onClick={this.changeShowPrimaryBox}>{t('closed.back')}</p>
                </div>

                <button className={!this.state.text ? 'report-button disabled' : 'report-button'}>
                    {t('report.nextButton')}
                </button>
            </form>
        )
    }

    renderContactForm = () => {
        const { t } = this.props;
        return (
            <div>
                <h1 className="contact-form-title">{t('report.contactFormTitle')}</h1>
                <form className="contact-form" onSubmit={this.onSubmitContactForm}>
                    <input type="email" className="report-input" placeholder={t('report.contactFormPlaceholderEmail')}
                           value={this.state.email}
                           onChange={(event) =>
                               this.handleChangeContactField(event, 'email')}
                    />
                    <span className="or-span">{t('report.and/or')}</span>
                    <input type="tel" className="report-input" pattern="[0-9]{10}"
                           placeholder={t('report.contactFormPlaceholderPhoneNumber')}
                           value={this.state.phoneNumber}
                           onChange={(event) =>
                               this.handleChangeContactField(event, 'phoneNumber')}
                           autoFocus
                    />
                    <button type="submit" className="report-button">{t('report.button')}</button>
                </form>
            </div>
        )
    }

    render() {
        const {t} = this.props;
        return (
            <div className='report-background'>
                <div className='report-container'>
                    <div className='report-address'>
                        <h1 className='report-title'>{t('report.addressText')}</h1>
                        <div className='report-location__address'>
                            <span>{this.state.address}</span>
                        </div>
                    </div>
                    <div className='report-content'>
                        {this.state.success ? this.renderThanks() :
                            this.state.showContactForm ? this.renderContactForm() : this.renderReportForm() }
                    </div>
                </div>
            </div>
        )
            ;
    }
}

Issue.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Issue);
