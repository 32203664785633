import Loader from 'react-loader-spinner';

export default function Spinner() {
    return (
        <Loader
            type="Puff"
            color="#1c93da"
            height={100}
            width={100}
            timeout={2000} //2 sec
        />
    )
}