import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

import HomeButton from './HomeButton';

import { useShowMenu } from '../contexts/ShowMenuProvider';

import PropTypes from 'prop-types';
import { translate } from 'react-switch-lang';

function Menu({t, menuItems}) {
    const { showMenu, handleCloseMenu } = useShowMenu();

    const classNameButton = 'btn-lg w-100 mb-3 MenuItemButton';

    return (
        <Offcanvas show={showMenu} onHide={handleCloseMenu} placement='end'>
            <Offcanvas.Header closeButton>
                <HomeButton />
            </Offcanvas.Header>
            <Offcanvas.Body className='d-flex flex-column justify-content-center align-items-center vh-100'>
                <Nav className='w-100 text-center'>
                    {menuItems.map(item => {
                        return (
                            <Button key={item} as={Link} to={`/${item}`} onClick={handleCloseMenu} className={classNameButton}>
                                {t(`menu.${item}`)}
                            </Button>
                        )
                    })
                    }
                </Nav>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

Menu.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Menu);