import PropTypes from "prop-types";
import { translate } from 'react-switch-lang';

function AvtomatAddress({ t, address }) {
    return (
        <div className='ready-address'>
            <h1 className='ready-title'>{t('avtomatAddress.title')}</h1>
            <div className='ready-location__address'>
                <span>{address}</span>
            </div>
        </div>
    )
}

AvtomatAddress.propType = {
    t: PropTypes.func.isRequired
}

export default translate(AvtomatAddress);