import { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';

import {
    setTranslations,
    setLanguageCookie,
    setLanguage,
    getLanguage,
    setDefaultLanguage
} from 'react-switch-lang';
import en from '../translations/en.json';
import ru from '../translations/ru.json';
import ua from '../translations/ua.json';

setTranslations({en, ru, ua});
setDefaultLanguage('ua');
setLanguageCookie();

function getCookie(cookieName) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + cookieName + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}

export default function SwitchLang() {
    let currLang = getLanguage();
    const cookieLang = getCookie('language');
    if (cookieLang && cookieLang.length > 1) {
        currLang = cookieLang;
        setLanguage(currLang);
    }
    const [lang, setLang] = useState(currLang);

    const setCurrentLang = (lang) => {
        setLanguage(lang);
        setLang(lang);
    }

    return (
        <Nav className='lang-switch flex-row'>
            <Button variant='link' onClick={() => setCurrentLang('ua')} className={lang === 'ua' ? 'active' : ''}>UA</Button>
            {/* <Button variant='link' onClick={() => setCurrentLang('ru')} className={lang === 'ru' ? 'active' : ''}>RU</Button> */}
            <Button variant='link' onClick={() => setCurrentLang('en')} className={lang === 'en' ? 'active' : ''}>EN</Button>
        </Nav>
    )
}